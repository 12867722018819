
import axios from '@/config/httpConfig'
// 保存共享规则
export function GetShareSaveShareRule(data) {
    return axios.post('/share/saveShareRule', data)
}

// 删除共享规则
export function GetShareDeleteShareRule(data) {
    return axios.post('/share/deleteShareRule', data)
}
