<template>
  <div>
    <!-- 共享信息列表 -->
    <el-dialog
      :title="$t('label.listofshare')"
      :visible.sync="dialogVisible"
      width="1179px"
    >
      <span
        style="
          width: 204px;
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: #080707;
        "
      >
        <!-- 以下列出的用户可以看到此记录的查询 -->
        {{ $t("label.listofshare.remark") }}
      </span>
      <el-card class="box-card">
        <el-table :data="shares" style="width: 100%">
          <!-- 操作 -->
          <el-table-column prop="date" :label="$t('label_partner_all_operate')">
            <template slot-scope="scope">
              <!-- 所有人 -->
              <span
                @click="
                  scope.row.rowcause === $t('label.referperson')
                    ? prohibit()
                    : handleEdit(scope.$index, scope.row)
                "
              >
                <!-- 编辑 -->
                {{ $t("pagecreator_page_button_edit") }}
              </span>
              /
              <span
                @click="
                  scope.row.rowcause === $t('label.referperson')
                    ? prohibit()
                    : handleEdits(scope.$index, scope.row)
                "
              >
                <!-- 删除 -->
                {{ $t("component_setup_tabs_label_delete") }}
              </span>
            </template>
          </el-table-column>
          <!-- 类型 -->
          <el-table-column prop="type" :label="$t('label.wei.polltype')">
          </el-table-column>
          <!-- 名称 -->
          <el-table-column prop="name" :label="$t('label_chatter_name')">
          </el-table-column>
          <!-- 访问权限级别 -->
          <el-table-column
            prop="accesslevel"
            :label="$t('label.accesslimittype')"
          >
          </el-table-column>
          <!-- 原因 -->
          <el-table-column prop="rowcause" :label="$t('label.reason')">
          </el-table-column>
        </el-table>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" class="clone">
          <!-- 返回 -->
          {{ $t("label.back") }}
        </el-button>
        <el-button type="primary" @click="add" class="submit">
          <!-- 添加 -->
          {{ $t("label.add") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 共享设置 -->
    <el-dialog
      :title="$t('label.sharerule')"
      :visible.sync="dialogVisibles"
      width="1179px"
    >
      <span
        style="height: 12px; font-size: 12px; font-weight: 400; color: #080707"
      >
        <!-- 指定此记录的共享。您可与用户，角色，角色及下属，区域，区域及下级区域所对应的用户共享此记录相关数据。个别共享只能用于授予对数据更广的访问权限，而不用与限制访问权限 -->
        {{ $t("label.sharerule.remark") }}
      </span>
      <div>
        <div style="clear: both"></div>
      </div>
      <div
        style="
          border: 1px solid #ccc;
          margin-top: 10px;
          height: 49px;
          border-left: 0;
          border-right: 0;
        "
      >
        <span style="line-height: 49px">
          <!-- 访问权限级别 -->
          {{ $t("label.accesslimittype") }}
        </span>
        <!-- 读/写 -->
        <el-select :placeholder="$t('label.readandwrite')" v-model="value">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="adds" class="submit">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
        <el-button @click="clones" class="clone">
          <!-- 返回 -->
          {{ $t("label.back") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 点击添加后，共享设置弹窗 -->
    <el-dialog
      :title="$t('label.sharerule')"
      :visible.sync="dialogVisibless"
      width="1179px"
    >
      <span
        style="height: 12px; font-size: 12px; font-weight: 400; color: #080707"
      >
        <!-- 指定此记录的共享。您可与用户，角色，角色及下属，区域，区域及下级区域所对应的用户共享此记录相关数据。个别共享只能用于授予对数据更广的访问权限，而不用与限制访问权限 -->
        {{ $t("label.sharerule.remark") }}
      </span>
      <div
        style="
          border: 1px solid #ccc;
          margin-top: 10px;
          border-left: 0;
          border-right: 0;
        "
      >
        <Shuttle ref="shuttles" is-group @memberFun="memberFun"></Shuttle>
        <div style="clear: both; margin-bottom: 15px"></div>
        <span
          style="
            margin-right: 10px;
            width: 24px;
            height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #080707;
          "
        >
          <!-- 权限 -->
          {{ $t("label.limit") }}
        </span>
        <el-radio v-model="radio" label="1">
          <!-- 只读 -->
          {{ $t("label.readonly") }}
        </el-radio>
        <el-radio v-model="radio" label="2">
          <!-- 读写 -->
          {{ $t("label.readwrite") }}
        </el-radio>
        <div style="margin-bottom: 15px"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clone" class="clone">
          <!-- 返回 -->
          {{ $t("label.back") }}
        </el-button>
        <el-button type="primary" @click="submit" class="submit">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
      <div
        style="
          width: 1179px;
          height: 100%;
          position: absolute;
          left: -1px;
          top: 0px;
        "
        v-loading="loading"
        v-if="isloading"
      ></div>
    </el-dialog>
  </div>
</template>
<script>
import Shuttle from "../shuttle/index.vue";
import {
  GetShareSaveShareRule,
  GetShareDeleteShareRule,
} from "./api.js";
export default {
  props: {
    shares: {
      type: Array,
    },
    dataId: {
      type: String,
      default: "",
    },
  },
  components: {
    Shuttle,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      dialogVisibless: false,
      isloading: false,
      radio: "1",
      options: [
        {
          value: "1",
          label: this.$i18n.t("label.readonly"),
          //只读
        },
        {
          value: "2",
          label: this.$i18n.t("label.readwrite"),
          // 读写
        },
      ],
      value: "",
      Info: "",
      str: "",
      loading: true,
      id: this.$route.params.id || this.dataId,
    };
  },
  methods: {
    handleEdit(index, row) {
      this.Info = row;
      this.value = row.accesslevel;
      this.dialogVisible = false;
      this.dialogVisibles = true;
    },
    async handleEdits(index, row) {
      this.Info = row;
      let params = {
        parentId: this.id,
        shareRuleId: this.Info.id,
      };
      let res = await GetShareDeleteShareRule(params);
      if (res.result) {
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
        this.$parent.Getrules();
      } else {
        this.$message.error(this.$i18n.t("label.ems.delete.false"));
      }
    },
    add() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
      this.dialogVisibless = true;
    },
    async adds() {
      let params = {
        parentId: this.id,
        shareRuleId: this.Info.id,
        accessLevel: this.value,
      };

      let res = await GetShareSaveShareRule(params);
      if (res.result) {
        this.value = "";
        this.$parent.Getrules();
        this.dialogVisible = true;
        this.dialogVisibles = false;
      }
    },
    async submit() {
      this.$refs.shuttles.curGroupLists();
      if (this.str && this.str.length > 0) {
        this.isloading = true;
        let params = {
          parentId: this.id,
          selected: this.str,
          accessLevel: this.radio,
        };
        let res = await GetShareSaveShareRule(params);
        if (res.result) {
          this.$refs.shuttles.clrears();
          this.isloading = false;
          this.$parent.Getrules();
          this.dialogVisibless = false;
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_add_success"), //添加成功
              type: "success",
            });
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t("vue_label_notice_add_fail"), //添加失败
            type: "error",
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label_please_select_a_member"), //请选择成员
          type: "warning",
        });
      }
    },
    clones() {
      this.dialogVisible = true;
      this.dialogVisibles = false;
    },
    open() {
      this.dialogVisible = !this.dialogVisible;
    },
    memberFun(val) {
      this.str = val;
    },
    prohibit() {
      this.$message.info(this.$i18n.t("label.weixin.donot.permission"));
    },
    clone() {
      this.$refs.shuttles.curGroupList = [];
      this.$refs.shuttles.clrears();
      this.dialogVisibless = false;
    },
  },
  watch: {
    $route(to, from) {
      this.id = to.params.id;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  height: 538px;
  position: relative;
}

::v-deep .el-dialog__title {
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #080707;
  line-height: 21px;
}

::v-deep .el-dialog__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

::v-deep .el-table {
  height: 365px;
  overflow-y: auto;
  text-align: center;
}

/* ::v-deep .is-leaf{
    text-align: center;
  } */
::v-deep .cell {
  cursor: pointer;
  line-height: 27px;
}

::v-deep .el-input__inner {
  width: 130px;
  height: 27px;
  margin-left: 10px;
}

::v-deep .el-select__caret {
  line-height: 29px;
}

::v-deep .submit {
  width: 70px;
  height: 30px;
  background: #1b5297;
  position: relative;
  top: 4px;
}

::v-deep .clone {
  width: 70px;
  height: 30px;
  position: relative;
  top: 4px;
}

::v-deep .el-radio__label {
  width: 24px;
  height: 12px;
  font-size: 12px;

  font-weight: 400;
  color: #080707;
  line-height: 18px;
}

::v-deep .el-dialog__body {
  border: 0 !important;
  padding: 0px 36px 16px 36px !important;
}
::v-deep .el-dialog__footer {
  border-top: 1px solid #d8d8d8;
  text-align: right;
}
</style>